<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Employer</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <img width="200" height="200" src="@/assets/images/user-placeholder.png"
                                     class="users-avatar-shadow rounded mb-2 pr-2 ml-1" alt="avatar">
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td>{{user.name}}</td>
                                    </tr>

                                    <tr>
                                        <th>Email:</th>
                                        <td>{{user.email}}</td>
                                    </tr>

                                    <tr>
                                        <th>Contact #</th>
                                        <td>{{user.mobile_number}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-for="business in user.business_detail" :key="business.id">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Business Detail</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <img v-if="checkImage(business.company_logo)"
                                     :src="business.company_logo"
                                     width="200" height="200"
                                     class="users-avatar-shadow rounded mb-2 pr-2 ml-1" alt="avatar">
                                <img v-else
                                     width="200" height="200"
                                     src="@/assets/images/user-placeholder.png"
                                     class="users-avatar-shadow rounded mb-2 pr-2 ml-1" alt="avatar">
                            </div>

                            <div class="col-sm-4 col-md-9">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col" class="text-white">Industry</th>
                                        <th scope="col" class="text-white">Company Name</th>
                                        <th scope="col" class="text-white">UEN</th>
                                        <th scope="col" class="text-white">Company Size</th>
                                        <th scope="col" class="text-white">Contact</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{{business.industry ? business.industry.title : ""}}</td>
                                        <td>{{business.name}}</td>
                                        <td>{{business.unique_entity_number}}</td>
                                        <td>{{business.company_size}}</td>
                                        <td>{{business.contact_no}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Completed Jobs</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <div id="completed-table_length" class="dataTables_length">
                                    <!-- <label class="d-inline-flex align-items-center">
                                      Show
                                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label> -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Sete Status -->
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-6 col-md-3">
                                <div id="completed-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="user.completed_jobs"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(type)="job">
                                    {{job.item.type == 1 ? "1-Day Booking": "Multiple day Booking"}}
                                </template>
                                <template v-slot:cell(start_time)="job">
                                    {{getDateWithTimeZone(job.item.start_time)}}&nbsp;
                                    {{getDateWithTimeZone(job.item.start_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(end_time)="job">
                                    {{getDateWithTimeZone(job.item.end_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(actions)="job">
                                    <button class="btn btn-primary btn-sm" @click="job_details(job)">Details
                                    </button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Pending Jobs</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <div id="pending-table_length" class="dataTables_length">
                                    <!-- <label class="d-inline-flex align-items-center">
                                      Show
                                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label> -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Sete Status -->
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-6 col-md-3">
                                <div id="pending-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="user.pending_jobs"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(type)="job">
                                    {{job.item.type == 1 ? "1-Day Booking": "Multiple day Booking"}}
                                </template>
                                <template v-slot:cell(start_time)="job">
                                    {{getDateWithTimeZone(job.item.start_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(end_time)="job">
                                    {{getDateWithTimeZone(job.item.end_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(actions)="job">
                                    <button class="btn btn-primary btn-sm" @click="job_details(job)">Details
                                    </button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Cancelled Jobs</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <div id="cancelled-table_length" class="dataTables_length">
                                    <!-- <label class="d-inline-flex align-items-center">
                                      Show
                                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label> -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Sete Status -->
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-6 col-md-3">
                                <div id="cancelled-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="user.cancelled_jobs"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(type)="job">
                                    {{job.item.type == 1 ? "1-Day Booking": "Multiple day Booking"}}
                                </template>
                                <template v-slot:cell(start_time)="job">
                                    {{getDateWithTimeZone(job.item.start_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(end_time)="job">
                                    {{getDateWithTimeZone(job.item.end_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(actions)="job">
                                    <button class="btn btn-primary btn-sm" @click="job_details(job)">Details
                                    </button>&nbsp;
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="row">-->
<!--            <div class="col-12">-->
<!--                <div class="card">-->
<!--                    <div class="card-body">-->
<!--                        <h4 class="card-title">Start Jobs</h4>-->
<!--                        <p class="card-title-desc"></p>-->
<!--                        <div class="row mb-md-2">-->
<!--                            <div class="col-sm-6 col-md-3">-->
<!--                                <div id="start-table_length" class="dataTables_length">-->
<!--                                    &lt;!&ndash; <label class="d-inline-flex align-items-center">-->
<!--                                      Show-->
<!--                                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries-->
<!--                                    </label> &ndash;&gt;-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="col-sm-6 col-md-3">-->
<!--                                <div class="col-sm-10">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            &lt;!&ndash; Sete Status &ndash;&gt;-->
<!--                            <div class="col-sm-6 col-md-3">-->
<!--                                <div class="col-sm-10">-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            &lt;!&ndash; Search &ndash;&gt;-->
<!--                            <div class="col-sm-6 col-md-3">-->
<!--                                <div id="start-table_filter" class="dataTables_filter text-md-right">-->
<!--                                    <label class="d-inline-flex align-items-center">-->
<!--                                        Search:-->
<!--                                        <b-form-input-->
<!--                                                v-model="filter"-->
<!--                                                type="search"-->
<!--                                                placeholder="Search..."-->
<!--                                                class="form-control form-control-sm ml-2"-->
<!--                                        ></b-form-input>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            &lt;!&ndash; End search &ndash;&gt;-->
<!--                        </div>-->
<!--                        &lt;!&ndash; Table &ndash;&gt;-->
<!--                        <div class="table-responsive mb-0">-->
<!--                            <b-table-->
<!--                                    :items="user.start_jobs"-->
<!--                                    :fields="fields"-->
<!--                                    responsive="sm"-->
<!--                                    :per-page="perPage"-->
<!--                                    :current-page="currentPage"-->
<!--                                    :sort-by.sync="sortBy"-->
<!--                                    :sort-desc.sync="sortDesc"-->
<!--                                    :filter="filter"-->
<!--                                    :filter-included-fields="filterOn"-->
<!--                                    @filtered="onFiltered"-->
<!--                            >-->
<!--                                <template v-slot:cell(type)="job">-->
<!--                                    {{job.item.type == 1 ? "1-Day Booking": "Multiple day Booking"}}-->
<!--                                </template>-->
<!--                                <template v-slot:cell(start_time)="job">-->
<!--                                    {{getDateWithTimeZone(job.item.start_time)}}&nbsp;-->
<!--                                </template>-->
<!--                                <template v-slot:cell(end_time)="job">-->
<!--                                    {{getDateWithTimeZone(job.item.end_time)}}&nbsp;-->
<!--                                </template>-->
<!--                                <template v-slot:cell(actions)="job">-->
<!--                                    <button class="btn btn-primary btn-sm" @click="job_details(job)">Details-->
<!--                                    </button>-->
<!--                                </template>-->
<!--                            </b-table>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">User Location</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-4 col-md-4">
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>City:</th>
                                        <td>{{user.location.city}}</td>
                                    </tr>
                                    <tr>
                                        <th>Street Address:</th>
                                        <td>{{user.location.street_address}}</td>
                                    </tr>
                                    <tr>
                                        <th>postal_code:</th>
                                        <td>{{user.location.postal_code}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-sm-8 col-md-8">
                                <template>
                                    <gmap-map
                                            :center="{ lat: parseFloat(user.location.latitude), lng: parseFloat(user.location.longitude) }"
                                            :zoom="16" style="height: 500px">
                                        <GmapMarker
                                                :key="index"
                                                v-for="(m, index) in markers"
                                                :position="m"
                                                :clickable="true"
                                                :draggable="true"
                                                @click="center=m"
                                        />
                                    </gmap-map>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import moment from 'moment';


    export default {
        page: {
            title: "Employer Detail",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader},
        data() {
            return {
                color: '',
                user: {
                    name: "",
                    email: "",
                    mobile_number: "",
                    avatar_src: null,
                    status: "",
                    last_active_at: "",
                    activated_at: "",
                    availability: 1,
                    documents: [],
                    location: {
                        street_address: "",
                        city: "",
                        latitude: 1.29326540,
                        longitude: 103.83096222,
                    }
                },
                title: "Employer Detail",
                items: [
                    {
                        text: "Any",
                        href: "/"
                    },
                    {
                        text: "Employers Detail",
                        href: "/"
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                // pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "title", label: 'Title', sortable: false},
                        {key: "type", label: 'Type', sortable: false},
                        {key: "total_workers", label: 'Workers'},
                        {key: "status", label: 'Status'},
                        {key: "start_time", label: 'Start Time', sortable: true},
                        {key: "end_time", label: 'end Time', sortable: true},
                        {key: "actions", sortable: false},
                    ],
                markers: [
                    {
                        lat: 31.5313,
                        lng: 74.3183,
                        label: 'Surat'
                    }
                ]
            };
        },
        computed: {}
        ,
        mounted() {
            if (!localStorage.getItem('userDetail')) {
                this.$router.push('/employer')
            }
            let userDetail = localStorage.getItem('userDetail');
            this.user = JSON.parse(userDetail);
            console.log("user detail", this.user);
            this.markers[0].lat = parseFloat(this.user.location.latitude)
            this.markers[0].lng = parseFloat(this.user.location.longitude)
            this.markers[0].label = this.user.location.street_address
        }
        ,
        created() {
        }
        ,

        methods: {
            // imageLoaderError(event) {
            //     event.target.src = "../assets/images/user-placeholder.png"
            // },
            checkImage(url) {
                const img = new Image();
                img.src = url;
                if (img.complete) {
                    return true;
                } else {
                    img.onload = () => {
                        return true
                    };
                    img.onerror = () => {
                        return false;
                    };
                }

            },
            getTimeZone() {
                return Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            job_details(job) {
                this.$router.push({name: 'job_details', params: {job_details: job.item, job_title: job.item.title}})
            },
            getDateWithTimeZone(date) {
                return date;
                // let timeZone = this.getTimeZone();
                // let a = this.$moment.tz(date, timeZone);
                // let d = new Date(a._d);
                // let day = d.getDate().toString();
                // let getDate = day.length === 1 ? "0" + day : day
                // let month = (d.getMonth() + 1).toString();
                // let getMonth = month.length === 1 ? "0" + month : month
                // let seconds = (d.getSeconds()).toString();
                // let getSeconds = seconds.length === 1 ? "0" + seconds : seconds
                // let minutes = (d.getMinutes()).toString();
                // let getMinutes = minutes.length === 1 ? "0" + minutes : minutes
                // let hours = (d.getHours()).toString();
                // let getHours = hours.length === 1 ? "0" + hours : hours
                //
                // return d.getFullYear() + "-" + getMonth + "-" + getDate + " " +
                //     getHours + ":" + getMinutes + ":" + getSeconds;
            },
            ShowSubSpeciality(subSpeciality) {
                let data = "";
                subSpeciality.map(x => {
                    data += x.title + ","
                })
                return data.substring(0, data.length - 1);
            },
            totalDays(job) {
                let timeZone = this.getTimeZone()
                let startDate = moment.tz(job.start_time, timeZone).format('MM/DD/YYYY');
                let endDate = moment.tz(job.end_time, timeZone).format('MM/DD/YYYY');
                let future = moment(endDate);
                let start = moment(startDate);
                return future.diff(start, 'days') + 1;

            }
        }
    }
    ;
</script>
